<template>
    <div class="productDetail">
      <div class="empty">

      </div>
        <div class="container">
            <div class="row">
                <div class="col-10 mx-auto">
                    <box :border="border">
                        <h1 class="title" slot="title">{{product[0].category.name}}</h1>
                        <div class="detail detail-view mx-auto mt-4" slot="body">
                            <div class="product-image">
                              <button type="button" class="slick-prev" @click="slickPrev()"><img :src="'/img/'+this.slickIcon" class="icon prev-icon" /></button>
                                <slick ref="slick" id="slider" :options="options">
                                    <router-link :to="{ name:'Product', params: { language: $i18n.locale, product:product[0].title }}" v-for="productpic in product[0].data.picture" :key="productpic">
                                        <img :src='"/img/"+productpic' alt="" class="w-100">
                                    </router-link>
                                </slick>
                                <button type="button" class="slick-next" @click="slickNext()"><img :src="'/img/'+this.slickIcon" class="icon next-icon" /></button>
                                <slick id="sliderNav" :options="options2">
                                        <img :src='"/img/"+productpic' @error="$event.target.src='/img/default.png'" v-for="productpic in product[0].data.picture" :key="productpic" :alt="product[0].name" class="w-100 px-3">

                                </slick>
                            </div>
                            <div class="product-detail mt-3">
                                <div class="product-title" :class="border+'-title'">
                                    <h2 class="de bnl px-3 pt-1" >{{ product[0].data.name }}</h2>
                                </div>
                                <div class="detaile mt-3">
                                    <p dir="rtl">{{ product[0].data.txt }}</p>
                                </div>
                            </div>
                        </div>
                    </box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Box from './Box.vue'
import Slick from 'vue-slick'
import '@/../node_modules/slick-carousel/slick/slick.css';
import httpClient from '@/services/http.service'
    export default {
    components: { Box,Slick },
        name:'ProductDetail',
        props:{
            border:String,
        },
        data(){
            return{
                options:{
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll:1,
                    asNavfor:'#sliderNav',
                    rtl:true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    arrows:false,
                },
                options2:{
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  asNavFor: '#slider',
                  dots: false,
                  arrows:false,
                  centerMode: false,
                  focusOnSelect: true,
                  rtl:true
                },
                product : new Array(),
                errors : null,
                category:this.$route.params.category,
                slickIcon:'17.png',
            }
        },
        methods: {
          slickPrev() {
            this.$refs.slick.prev();
          },
          slickNext() {
            this.$refs.slick.next();
          }
        },
        async mounted() {
          try {
            const {status,data} = await httpClient.get('/fa/products',{params:{
              title: this.$route.params.product,
              expand:'category'
            }});
            if (status == 200) {
              this.product = data
            }
          } catch (e) {
            this.error = 'Connection Is Faild';
          }
          if (this.category == 'tajhiz') {
            this.slickIcon = '16.png';
          }
        }
    }
</script>

<style lang="scss">
.icon{
  width: 100%;
}
.prev-icon{
  transform: rotate() 1turn;
}
.slick-prev{
  position: absolute;
  //border-radius: 50%;
  border:none;
  background: transparent;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  top:40%;
  left: -50px;
}
.slick-next{
    position: absolute;
    //border-radius: 50%;
    border:none;
    background: transparent;
    width: 50px;
    height: 50px;
    opacity: 0.7;
    top:40%;
    right: -50px;
    transform: rotate(180deg);
    //content: url('../../public/img/17.png');
}
    .bnl{
        border-left:none;
    }
    .product-title{
        width: max-content;
    }
    .de{
        font-size: 20px;
        font-weight: bolder;
        color: #fff;
    }
    .detaile{
        font-size: 15px;
        color: #fff;
        text-align: justify;
    }
    .detail-view{
      padding: 30px;
      background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    }
</style>
