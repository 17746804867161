<template>
    <div class="product">
        <navbar></navbar>
        <product-detail :border="border"></product-detail>
        <div class="empty">

        </div>
        <Footer />
    </div>
</template>

<script>
import ProductDetail from '../components/ProductDetail.vue'
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer';
    export default {
    components: { Navbar, ProductDetail,Footer },
        name:'Product',
        data(){
            return{
                category:this.$route.params.category,
                border:'border-tajhiz',
            }
        },
        created(){
            if(this.category == 'plast'){
                this.border = 'border-plast';
            }
        }
    }
</script>

<style lang="scss" scoped>
.product{
    min-height: 1200px;
}
</style>
